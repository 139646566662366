.nav {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 1rem 2rem;
  gap: 5rem;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
  background-color: #fff;
  @media  screen and (max-width: 980px) {
    justify-content: space-between;
    padding: 1rem;
    gap: 0.8rem; }
  &_brand {
    @media  screen and (max-width: 980px) {
      order: 1; } }
  &_contact {
    margin-left: auto;
    @media  screen and (max-width: 980px) {
      order: 2;
      flex-shrink: 2;
      margin-left: auto; }
    .button {
      @media  screen and (max-width: 980px) {
        border-radius: 1.5rem;
        margin: 0;
        padding: 0.6rem 1rem;
        font-size: 1.2rem;
        text-transform: uppercase; } } }
  &_main {
    margin-left: 2rem;
    display: flex;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: var(--text);
    gap: 1.5rem;
    @media  screen and (max-width: 980px) {
      flex-direction: column;
      transform: translateY(100%);
      width: 100%;
      display: none; } }
  &_item {
    @media  screen and (max-width: 980px) {
      border-bottom: 1px solid #fff;
      margin-bottom: 2px;
      padding-left: 0.5rem; } }
  &_link {
    transition: .4s ease-in-out;
    padding: 0.4rem 0.4rem;
    cursor: pointer;
    &.active {
      @extend %active; }
    @media  screen and (max-width: 980px) {
      display: inline-block;
      width: 100%; }
    &:hover {
      border-bottom: 0.3rem solid var(--blue-color); }
    &:active {
      border-bottom: 0.3rem solid var(--blue-color); } }

  #toggle_btn {
    display: none; }
  label {
    opacity: 0;
    @media  screen and (max-width: 980px) {
      opacity: 1;
      order: 3; } } }


@media  screen and (max-width: 980px) {
  #toggle_btn:checked ~ .nav_main {
    // transform: translateY(-100%)
    transition: .4s ease-in-out;
    display: flex;
    flex-direction: column;
    position: absolute;
    transform: translate(-1.2rem, 73%);
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: rgba(37, 50, 180, 0.93);
    color: #fff; } }

.logo {
  height: 35px;
  aspect-ratio: 223/58; }
