.hero {
  position: relative;
  align-self: stretch;
  display: flex;
  height: 84vh;
  margin-top: 5rem;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  @media  screen and (max-width: 980px) {
    min-height: 84vh;
    margin-top: 3.5rem; }
  @media  screen and (max-width: 380px) {
    min-height: 96vh; }
  &-home {
    background-image: url(/images/home_background.png);
    background-position: bottom;
    background-size: cover;
    @media  screen and (max-width: 667px) {
      background-position: center; } }

  &-howpage {
    background-image: url(/images/how-page-background.png);
    background-position: bottom;
    background-clip: content-box;
    @media  screen and (max-width: 667px) {
      background-position: center;
      height: 70vh; } }

  &-howpage {
    @media  screen and (max-width: 667px) {
      p {
        margin-top: 4rem; }
      form {
        top: 60%;
        left: 5%; } }

    & + .description {
      margin-top: 5rem;
      @media  screen and (max-width: 667px) {
        margin-top: 2.5rem; }
      h3 {
        margin-bottom: 6rem;
        @media  screen and (max-width: 667px) {
          margin-bottom: 2.5rem; } }
      .button {
        margin-top: 8rem;
        @media  screen and (max-width: 667px) {
          margin-top: 4rem; } } } }
  &-whypage {
    background-image: url(/images/houses-background.png);
    background-position: bottom;
    @media  screen and (max-width: 667px) {
      background-position: center;
      height: 70vh;
      form {
        top: 60%;
        left: 5%; } }

    & + .description {
      h3 {
        width: 75%;
        margin-right:  auto;
        margin-left: auto; }
      .button {
        margin-top: 6rem;
        @media  screen and (max-width: 667px) {
          margin-top: 3rem; } } } }
  &-about {
    background-image: url(/images/houses-background.png);
    background-position: bottom;
    background-clip: content-box;
    @media  screen and (max-width: 667px) {
      background-position: center;
      height: 70vh; }
    form {
      @media  screen and (max-width: 667px) {
        top: 60%;
        left: 5%; } } }
  &-contact {
    background-image: url(/images/contact-us-background.png);
    background-position: bottom;
    background-clip: content-box;
    @media  screen and (max-width: 667px) {
      background-position: center;
      height: auto; } }
  &_wrapper {
    position: relative;
    padding: 2rem;
    width: 100%;
    margin-top: 5rem;
    @media  screen and (max-width: 980px) {
      margin-top: 3rem; } }
  &_headline {
    // position: absolute
    // top: 20%
    color: #fff;
    font-size: 3.5rem;
    letter-spacing: 0.05rem;
    @media  screen and (max-width: 667px) {
      top: 7%;
      font-size: 3.0rem; } }
  &_bubble {
    --size: 84px;
    background: #fff;
    width: 380px;
    display: grid;
    grid-template-columns: 84px 1fr;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1rem 3rem 1rem 1rem;
    align-items: center;
    border-radius: 99rem;
    gap: 1.5rem;
    @media  screen and (max-width: 980px) {
      width: 320px; }

    &:first-child {
      position: absolute;
      right: 18%;
      top: 30%;
      @media  screen and (max-width: 980px) {
        right: 10%;
        top: 45%; } }
    &:last-child {
      position: absolute;
      right: 5%;
      bottom: 30%;
      @media  screen and (max-width: 992px) {
        right: 2rem;
        bottom: 15%; } } }
  &_testimonial {
    grid-column: 2/-1;
    align-self: end;
    margin-right: 1rem; }
  &_reviewer {
    grid-column: 2/-1;
    justify-self: end;
    align-self: end;
    margin-right: 3.5rem; }
  &_dp {
    grid-row: 1/-1;
    aspect-ratio: 1/1;
    height: var(--dp-size);
    @media  screen and (max-width: 667px) {
      --size: 75px; } }
  &_form {
    position: absolute;
    bottom: 30%;
    @media  screen and (max-width: 915px) {
      bottom: 10%;
      right: 42%;
      @media  screen and (max-width: 667px) {
        bottom: 5%;
        right: 2%; } } } }

.button {
  color: #fff;
  padding: 0.75rem 2.5rem;
  border-radius: 2.5rem;
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.15);
  span {
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    white-space: nowrap; }
  svg {
    fill: #fff;
    stroke: #fff;
    height: 1.0rem;
    width: 1.2rem; }
  &-purple, &-blue, &-white {
    span {
      font-weight: 600; } }
  &-purple {
    background-color: var(--purple-color); }
  &-blue {
    background-color: var(--blue-color);
    text-transform: uppercase; }
  &-white {
    background-color: #fff;
    color: var(--purple-color);
    svg {
      fill: var(--purple-color);
      stroke: var(--purple-color); } } }

// Caurosel Stars
.rating {
  color: #ef7420;
  font-size: 2rem; }

// Hero section  Phonenumber
.form {
  &_field {
    padding-left: 1.5rem;
    transition: opacity 600ms, transform 300ms;
    outline: none !important;
    border-width: 0.3rem;
    padding-left: 3.5rem;
    transition: opacity 600ms, transform 300ms;
    border: none;
    border-radius: 1rem;
    width: 100%;
    &:focus-visible {
      border-color: var(--purple-color); }
    &::placeholder {
      color: rgba(0, 0, 0, 0.5); } }
  &_phonenumber {
    height: 5rem;
    width: 35rem;
    border-color: rgba(130, 137, 144, 0.5);
    border-width: 0.3rem;
    border-style: solid;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    border-radius: 99rem;
    @media  screen and (max-width: 667px) {
      height: 3.5rem;
      width: 30rem; }
    .button {
      width: 50%;
      padding: 1.5rem;
      border: none;
      outline: none;
      border-radius: 99rem;
      background-color: var(--blue-color);
      color: #fff;
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 600;
      display: inline-flex;
      justify-content: center;
      @media  screen and (max-width: 667px) {
        padding: 1rem;
        font-weight: 300;
        font-size: 1rem; } } } }

.cards {
  display: flex;
  justify-content: space-evenly;
  gap: 3rem;
  @media  screen and (max-width: 667px) {
    flex-direction: column; } }
.card {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  background-color: #fff;
  gap: 1.5rem;
  border-radius: 2rem;
  box-shadow: 7px 7px 15px rgba(37, 50, 180, 0.4);
  @media  screen and (max-width: 667px) {
    padding: 1.2rem;
    gap: 1.2rem; } }

.faq {
  width: 50%;
  a {
    color: var(--purple-color);
    text-decoration: underline;
 }    // &:hover
  @media  screen and (max-width: 667px) {
    width: 100%; }
  &s {
    text-align: center;
    padding: 4rem 1rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center; }
  &_headline {
    margin-bottom: 2rem; }
  &_question {
    margin-top: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    user-select: none;
    &::after {
      content: "+";
      position: relative;
      color: var(--purple-color);
      font-size: 1.6rem; } }
  &.active &_question::after {
    content: "-"; }

  &_answer {
    max-height: 0;
    overflow: hidden;
    @media  screen and (max-width: 667px) {
      text-align: left; } }

  &.active &_answer {
    max-height: initial;
    border-radius: 2rem;
    box-shadow: 0.3 rem 0.3rem 1rem rgba(37, 50, 180, 0.4);
    padding: 1.5rem 0;
    font-size: 1.4rem;
    margin-top: 1rem;
    line-height: 1.8;
    overflow: wrap; } }

//section
.buy {
  align-self: stretch;
  text-align: center;
  font-size: 4rem;
  font-weight: 600;
  background-image: url(/images/we_buy_background.png);
  background-position: center;
  color: #fff;
  padding: 10rem 0;
  @media  screen and (max-width: 667px) {
    font-size: 2.5rem; } }

//section
.description {
  background-color: #fff;
  text-align: center;
  padding: 4rem 4%;
  margin-bottom: 1.5rem;
  .button {
    text-transform: capitalize;
    span {
      margin-left: 0.5rem;
      font-weight: 600;
      font-size: 1.5rem; } }
  @media  screen and (max-width: 667px) {
    padding: 2rem 6%;
    margin-bottom: 1.5rem;
    p {
      margin-top: 1.5rem; }
    .button {
      text-transform: capitalize; } } }


//section
.works {
  background-image: linear-gradient(to right, rgb(49, 61, 164) , rgb(44, 172, 220));
  text-align: center;
  align-self: stretch;
  padding: 4rem 0.5rem;
  img {
    transform: scale(0.65);
    @media  screen and (max-width: 667px) {
      scale: .65; } }
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      color: #fff; } }
  &_num {
    font-size: 3rem;
    font-weight: 700;
    color: var(--purple-color);
    @media  screen and (max-width: 667px) {
      font-size: 2.5rem; } }
  h4 {
    text-transform: uppercase; }
  &-card {
    width: 25rem;
    padding: 2rem;
    @media  screen and (max-width: 667px) {
      width: 80vw; } } }

.howpage {
  &_works {
    background-image: url(/images/charts_overlay.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    text-align: center;
    padding: 3rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.5rem;
    @media  screen and (max-width: 667px) {
      background-size: cover;
      gap: 2rem; }
    h3 {
      color: #fff;
      margin: 5rem auto;
      @media  screen and (max-width: 667px) {
        margin: 2.5rem auto; } } }
  &_wrapper {
    .button {
      margin-bottom: {}
      @media  screen and (max-width: 667px) {
        margin-bottom: 2.5rem; } } }
  &_content {
    text-align: left;
    p:first-of-type {
      font-weight: bold; }
    @media screen and (max-width: 991px) {
      grid-column: 1/-1; } } }
.process {
  background-color: #fff;
  display: flex;
  padding: 2.5rem;
  align-items: center;
  border-radius: 2rem;
  gap: 1rem;
  display: grid;
  grid-template-columns: 3rem 1fr;
  @media screen and (min-width: 667px) {
    grid-template-columns: 5rem 7.5rem 1fr; }
  @media screen and (min-width: 992px) {
    grid-template-columns: 5rem 7.5rem 20rem 1fr; }
  @media  screen and (max-width: 667px) {
    padding: 2rem;
    text-align: left;
    &es {
      margin-bottom: 2.5rem; }
    .h4 {
      grid-column: 1/-1; }
    img {
      margin: 0; } }
  &es {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-bottom: 5rem; }
  span {
    font-size: 4.5rem;
    font-weight: 600;
    color: var(--purple-color);
    @media  screen and (max-width: 667px) {
      font-size: 2.5rem; } }

  img {
    transform: scale(0.5); }
  h4 {
    text-align: left; } }

.reasons {
  align-self: stretch;
  margin-top: 2rem;
  background-image: url(/images/handshake_background.png);
  background-size: cover;
  background-position: center;
  padding: 3rem; }
.whypage {
  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    h3 {
      color: #fff;
      margin: 5rem 0; } }
  &_choice {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 25rem;
    padding: 2rem 3rem;
    border-radius: 4rem;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    text-align: center;
    @media  screen and (max-width: 667px) {
      width: auto;
      &s {
        flex-direction: column; } }
    &s {
      display: flex;
      gap: 3rem;
      justify-content: center; }
    h4 {
      text-transform: uppercase;
      margin: 1.2rem 0; }
    img {
      height: 9rem; }
    p:first-of-type {
      font-style: italic; } }

  &_comparison {
    align-self: stretch;
    background-image: url(/images/comparison_background.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 4rem;
    @media  screen and (max-width: 667px) {
      padding: 2rem; }
    h3, p, h4 {
      color: #fff;
      text-align: center; }
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.2rem; } }
  &_cards {
    display: flex;
    margin-top: 0 auto;
    gap: 3rem;
    padding: 1.5rem;
    @media  screen and (max-width: 667px) {
      flex-direction: column; } }
  &_card {
    background: #d9e0e3;
    padding: 3rem;
    border-radius: 2rem;
    h4 {
      color: #625c5c;
      margin-bottom: 1.5rem;
      text-align-last: left; }
    li {
      line-height: 1.8;
      margin-bottom: 0.8rem;
      @media  screen and (max-width: 667px) {
        margin-bottom: 0.4rem; } }
    &:first-child {
      border: 4px solid var(--purple-color);
      background-color: #fff;
      h4 {
        color: var(--purple-color); } }
    &:last-child {
      ul {
        list-style: disc; } } } }
// list item ticks
.list_icon {
  font-weight: 800; }

.home {
  &_values {
    display: flex;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    @media  screen and (max-width: 667px) {
      flex-direction: column; } }
  &_value {
    font-size: 1.8rem;
    font-weight: 600;
    display: grid;
    grid-template-columns: 20% 1fr;
    width: 27rem;
    text-align: left;
    align-items: center;
    gap: 1rem;
    @media  screen and (max-width: 667px) {
      width: 100%; }
    img {
      grid-column: 1/1;
      scale: 0.9;
      @media  screen and (max-width: 667px) {
        scale: 0.5; } } }
  &_problems {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(/images/solving_problems.png);
    background-size: cover;
    background-position: center;
    @media  screen and (max-width: 667px) {
      margin-top: 1rem; } }
  &_underlay {
    background-image: linear-gradient(to right, rgba(49, 61, 164, 0.85) , rgba(44, 172, 220,0.85));
    color: #fff;
    padding: 4.5rem;
    text-align: center;
    margin: 3rem 0;
    @media  screen and (max-width: 667px) {
      padding: 2.5rem;
      width: 85%;
      margin: 15%vw 0; }
    p:first-of-type {
      width: 70%;
      text-align: center;
      margin-left:  auto;
      margin-right: auto; }
    a {
      text-decoration: underline;
      font-weight: bold; }
    h3 {
      color: #fff;
      margin: 3rem auto;
      @media  screen and (max-width: 667px) {
        margin: 1.5rem auto; } } }
  &_problem {
    &::before {
      content: url(/images/house_icon.png); }
    li:first-child {
      margin-top: 1.5rem; }
    li {
      margin-bottom: 0.8rem;
      font-weight: bold; } }
  &_carousel {
    background-color: #f8f8f8;
    text-align: center;
    padding: 3.5rem;
    p:first-child {
      margin: 3rem 0; } }
  &_card {
    scroll-snap-align: end;
    box-shadow: none;
    &-left, &-right {
      --offset: -40px;
      transform: translateX(var(--offset));
      @media screen and (min-width: 1280px) {
        --offset: -75px; } }
    &-left {
      content: url(/images/left-chevron.png);
      position: absolute;
      bottom: 50%;
      scale: .75;
      left: 0;
      cursor: pointer;
      @media  screen and (max-width: 667px) {
        bottom: 50%;
        scale: .55; } }
    &-right {
      --offset: 40px;
      content: url(/images/right-chevron.png);
      position: absolute;
      bottom: 50%;
      scale: .75;
      cursor: pointer;
      right: 0;
      @media screen and (min-width: 1280px) {
        --offset: 75px; }
      @media  screen and (max-width: 667px) {
        bottom: 50%;
        scale: .55; } }
    &s {
      --gap: 25px;
      justify-content: initial;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: calc((100% - var(--gap) * 2) / 3);
      overflow-x: auto;
      scroll-snap-type: inline mandatory;
      scroll-margin-inline-start: 0;
      padding: 1.5rem;
      @media screen and (min-width: 667px) {
        scroll-padding-inline: 31px; }
      &-row {
        grid-auto-columns: calc(105% - var(--gap));
        scroll-padding-inline: 0;
        padding: 2.5rem 1rem; }
      @media  screen and (max-width: 667px) {
        padding: 0;
        grid-auto-columns: 100%; }
      &-wrap {
        position: relative;
        @media  screen and (max-width: 667px) {
          display: flex;
          flex-direction: column;
          padding: 1rem; } } }
    p:first-child {
      margin-bottom: 1.5rem; }
    p:first-of-type::before {
      content: url(/images/double_quotes.png);
      display: block;
      margin-bottom: 2rem;
      @media  screen and (max-width: 667px) {
        scale: .50;
        margin-bottom: 1rem; } }
    span:first-of-type {
      margin-bottom: -2rem; } }
  &_card-about { //about-page carousel//////////
    position: relative;
    p:first-of-type {
      margin-left: 6rem;
      @media  screen and (max-width: 667px) {
        margin-left: 1rem;
        margin-top: 4rem; } }
    p:first-of-type::before {
      content: url(/images/double_quotes.png);
      display: inline-block;
      margin-right: 3rem;
      transform: translateY(2rem);
      position: absolute;
      left: 2rem;
      @media  screen and (max-width: 667px) {
        display: inline-block;
        transform: translate(20rem, -10rem); } }
    span {
      align-self: flex-end; } } }


.about {
  &_corevalues {
    padding: 2rem;
    background-image: url(/images/handshake_background.png);
    background-repeat: no-repeat;
    background-size: cover;
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      h3, p {
        color: #fff;
        text-align: center; }
      h3 {
        margin-top: 5rem;
        @media  screen and (max-width: 667px) {
          margin-top: 4rem; } } } }
  &_values {
    display: flex;
    gap: 2rem;
    padding: 1rem;
    @media  screen and (max-width: 667px) {
      flex-direction: column;
      align-items: center; } }
  &_value {
    background-color: #fff;
    padding: 1.5rem;
    text-align: center;
    max-width: 27rem;
    border-radius: 2rem;
    @media  screen and (max-width: 667px) {
      max-width: 95%; }
    span, h4, p {
      margin-bottom: 3rem;
      @media  screen and (max-width: 667px) {
        margin-bottom: 1.5rem; } }
    p {
      color: #333;
      margin-bottom: 1.5rem; }

    span {
      font-size: 5rem;
      font-weight: 600;
      color: var(--blue-color);
      @media  screen and (max-width: 667px) {
        font-size: 4rem; } }
    h4 {
      text-transform: uppercase; } } }

.contact {
  width: 95%;
  display: flex;
  gap: 1.5rem;
  margin-top: 12rem;
  flex-direction: column;
  @media  screen and (max-width: 667px) {
    margin-top: 4rem;
    padding: 1rem;
    padding-bottom: 3rem; }
  &_textcontent {
    display: flex;
    gap: 1rem;
    @media  screen and (max-width: 667px) {
      flex-direction: column; } }
  &_headline {
    color: #fff;
    letter-spacing: 0.075rem;
    font-size: 40px;
    width: 50%;
    display: flex;
    flex-flow: column;
    @media  screen and (max-width: 667px) {
      top: 5%;
      font-size: 2rem; }
    > span {
      font-weight: 400;
      line-height: 1.1;
      &:first-child {
        flex: 1;
        padding-bottom: 40px; } }
    strong {
      font-weight: 800; } }
  &_info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-end;
    color: #fff;
    > span {
      flex: 1; }
    @media  screen and (max-width: 667px) {
      gap: 0.5rem;
      font-size: 1.4rem; }
    img {
      transform: scale(0.85);
      margin-right: 0.8rem;
      @media  screen and (max-width: 667px) {
        transform: scale(0.6); } } }
  &_fields {
    display: flex;
    gap: 2rem;
    @media  screen and (max-width: 667px) {
      flex-direction: column; } }
  &_details {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @media  screen and (max-width: 667px) {
      gap: 1rem;
      width: 100%; } }
  &_detail {
    padding: 1.5rem;
    @media  screen and (max-width: 667px) {
      padding: 0.8rem; }
    border-radius: 2.5rem;
    border: 0.3rem solid var(--light-grey-color); }

  &_message {
    width: 50%;
    position: relative;
    border-radius: 2.5rem;
    outline: none;
    border: none;
    @media  screen and (max-width: 667px) {
      width: 98%;
      border: 0.2rem solid var(--light-grey-color);
      min-height: 15rem;
      overflow: hidden; }
    textarea {
      border-radius: 2.5rem;
      border: 0.3rem solid var(--light-grey-color);
      resize: none;
      padding: 1.5rem;
      width: 100%;
      height: 100%;
      border-radius: 2.4rem;
      outline: none !important;
      border-width: 0.3rem;
      font-family: inherit;
      &:focus {
        border-color: var(--purple-color); }
      @media  screen and (max-width: 667px) {
        overflow-y: auto;
        padding: 0.8rem; } } }
  .button {
      position: absolute;
      bottom: 4%;
      right: 2%;
      @media  screen and (max-width: 667px) {
        bottom: 4%;
        right: 2%;
        padding: .6rem 1.2rem; } } }

.review {
  &_content {
    flex: 1;
    p {
      overflow: hidden;
      max-height: 22rem;
      @media screen and (min-width: 992px) {
        max-height: 19rem; } }
    &:hover p {
      max-height: initial; } }
  &_name {
    display: grid;
    text-align: center;
    margin: auto auto 4rem; } }

.form {
  &_close {
    --size: 1.5rem;
    --offset: 1rem;
    top: calc(var(--offset) * 0.75);
    right: var(--offset);
    width: var(--size);
    position: absolute;
    aspect-ratio: 1/1;
    cursor: pointer;
    z-index: 9; }

  @keyframes errorShow {
    0% {
      transform: translateY(var(--displacement)) scale(0);
      opacity: 1; }

    6% {
      opacity: 1;
      transform: translateY(var(--displacement)) scale(1); }

    96% {
      opacity: 1;
      transform: translateY(var(--displacement)) scale(1); }

    100% {
      transform: translateY(var(--displacement)) scale(0);
      opacity: 0; } }


  &_error {
    color: var(--color-danger);
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
    padding: 0.5rem 1rem;
    background-color: var(--white);
    position: relative;
    --displacement: -6.5rem;
    transform: translateY(var(--displacement)) scale(0);
    margin-bottom: var(--displacement);
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.06);
    opacity: 0;
    &.active {
      z-index: 10;
      opacity: 1;
      animation: 4s errorShow ease-in forwards; } }

  &_feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 10rem;
    padding: 25px 16px;
    background: var(--light);
    z-index: 95;
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.16);
    left: 16px;
    right: 16px;
    @media screen and (min-width: 992px) {
      width: 100%;
      --max: 720px;
      max-width: var(--max);
      --side: calc((100% - 720px) / 2);
      left: var(--side);
      right: var(--side); }

    &:not(.active) {
      display: none; } }


  &_fail, &_success {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    &:not(.active) {
      display: none; } } }

.overlay {
  position: fixed;
  align-items: center;
  background: transparent;
  z-index: 99999999;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  // top: 55px
  height: calc(100vh - 55px);
  cursor: pointer;
  perspective: 500px;
  display: flex;
  @media screen and (min-width: 992px) {
    top: 0;
    height: 100vh; }

  padding-left: 25px;
  padding-right: 25px;
  &:not(.active) {
    display: none; }

  &.active > div:not(.swipe_control):not(.gallery_close), &.active > img.active {
    animation: enlarge 1s ease-in forwards; }

  img {
    max-width: 100%;
    max-height: calc(100vh - 150px);
    margin: 30px;
    &:not(.active) {
      display: none; } } }
