* {
  &, &::before, &::after {
    box-sizing: inherit;
    overscroll-behavior-x: none;
    overscroll-behavior-y: auto;
    margin: 0;
    padding: 0; } }

body, html {
  scroll-behavior: smooth;
  scroll-padding-top: 1rem;
  box-sizing: border-box;
  font-kerning: normal;
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%; }

body {
  font-family: var(--font);
  color: var(--text);
  line-height: 1.5;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  font-kerning: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  font-size: 1.6rem; }

a {
  text-decoration: none;
  color: inherit; }

ol, ul {
  list-style: none; }

.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #000000;
  line-height: 1;
  font-weight: 700;
  --h1: 50px;
  --h2: 40px;
  --h3: 30px;
  --h4: 25px;
  @media screen and (min-width: 992px) {
    --h1: 60px;
    --h2: 45px;
    --h3: 35px;
    --h4: 20px; } }
.h1 {
  font-size: var(--h1); }

.h2 {
  font-size: var(--h2); }

.h3 {
  font-size: var(--h3); }

.h4 {
  font-size: var(--h4); }



img, svg {
  max-width: 100%;
  vertical-align: middle; }
img {
  height: auto;
  margin: auto;
  padding: 0; }

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  &, main {
    width: 100%;
    flex: 1; } }

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-container-width); }

%active {
  border-bottom: 0.4rem solid var(--blue-color); }

form, input {
  &.invalid {
    --color: #f00;
    border-color: var(--color);
    position: relative;
    &::after {
      content: "Please enter a 10-digit phone number";
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      color: var(--color);
      background: #fff;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      border: 1px solid rgba(255,0,0,0.25);
      font-size: 14px; } } }
input {
  &.invalid + span {
    position: relative;
    display: block;
    &::after {
      content: "Please enter a 10-digit phone number";
      position: absolute;
      top: 0.25rem;
      left: 0.15rem;
      color: #f00;
      background: #fff;
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
      border: 1px solid rgba(255,0,0,0.25);
      font-size: 14px; } } }
