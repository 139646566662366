.d {
  &-block {
    display: block; }
  &-grid {
    display: grid; }
  &-flex {
    display: flex; } }
.text {
  &-capitalize {
    text-transform: capitalize; }
  &-upper {
    text-transform: uppercase; } }
@for $i from 1 through 8 {
  $size: $i * 0.5rem;
  .pt-#{$i} {
    padding-top: $size;
    @media  screen and (max-width: 667px) {
      padding-top: $size * 0.5; } }
  .pb-#{$i} {
    padding-bottom: $size;
    @media  screen and (max-width: 667px) {
      padding-bottom: $size * 0.5; } }
  .py-#{$i} {
    padding-bottom: $size;
    padding-top: $size;
    @media  screen and (max-width: 667px) {
      padding-bottom: $size * 0.5;
      padding-top: $size * 0.5; } }
  .mt-#{$i} {
    margin-top: $size;
    @media  screen and (max-width: 667px) {
      margin-top: $size * 0.5; } }

  .mb-#{$i} {
    margin-bottom: $size;
    @media  screen and (max-width: 667px) {
      padding-bottom: $size * 0.5; } }

  .my-#{$i} {
    margin-top: $size;
    margin-bottom: $size;
    @media  screen and (max-width: 667px) {
      margin-top: $size * 0.5;
      margin-bottom: $size * 0.5; } } }
