html {
	--purple-color: #2532b4;
	--blue-color: #00a8e7;
	--light-grey-color: #828990;
	--light: #fff;
	--text: #000000;
	--text-medium-dark: #010101;
	--text-light-grey: #8e8e8e;
	--font: 'Poppins', sans-serif;
	--max-container-width: 992px; }
