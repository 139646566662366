.footer {
  padding-top: 9rem;
  background-color: var(--purple-color);
  align-self: stretch;
  @media  screen and (max-width: 667px) {
    padding-top: 5rem; }

  &_wrapper {
    padding: 0 1.5rem; }
  &_logo {
   margin-bottom: 2rem; }
  &_top {
    display: flex;
    border-bottom: 1px solid var(--light-grey-color);
    border-top: 1px solid var(--light-grey-color);
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;
    @media  screen and (max-width: 667px) {
      flex-direction: column;
      gap: 2.5rem;
      align-items: flex-start; } }

  &_question {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr 3fr;

    width: max-content;
    span:first-of-type {
      grid-column: 2/-1;
      color: var(--blue-color); }
    span:last-of-type {
      grid-column: 2/-1;
      grid-row: -2/-1;
      color: #fff; }

    &::before {
      content: "";
      background-image: url(/images/mail-footer-icon.png);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      grid-row: 1/4;
      margin-right: 1rem;
      transform: scale(1.25) translateY(-0.5rem); } }
  &_bottom {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0; }
  &_copyright {
    color: #fff; }

  &_scroll {
    border: none;
    outline: none;
    color: #fff;
    background-color: var(--purple-color);
    text-decoration: underline;
    text-decoration-color: var(--blue-color);

    &:hover {
      transform: scale(1.05); } } }
